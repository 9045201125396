


























































import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Watch } from "vue-property-decorator";
import Tooltip from "../tooltip.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import Debug from "../Debug.vue";

/**
 * Defines position and color of a single marker
 */
export interface IMarker {
  /**
   * relative position measured from top
   */
  top: number;

  /**
   * relative position measured from left
   */
  left: number;

  /**
   * Optional color property for each marker
   */
  color?: string;

  /**
   * text of the marker
   */
  text?: string;
}

/**
 * adds the css style to the IMarker interface
 */
export interface IMarkerAbsolute extends IMarker {
  style: string;
}

type VImage = {
  image: HTMLImageElement;
  getBoundingClientRect: Function;
};

@Component({
  components: { Tooltip, Debug }
})
export default class MImageMarker extends DarkModeHighlightMixin {
  @Prop() src!: string;
  @Prop({ default: "medium" }) markerSize!: "x-small" | "small" | "medium" | "large";
  @Prop() markers!: IMarker[];
  @Prop({ default: false }) deactivated!: boolean; // New prop to control deactivation
  @Prop({ default: false }) readonly!: boolean; // New prop to control deactivation
  @Prop({ default: 300 }) maxHeight!: number;

  imageLoaded = false;
  showMagnifier = false;
  magnifierSize = 200;
  magnificationFactor = 4;
  magnifierPosition = { x: 0, y: 0, xOffset: -1 };
  magnifierTimeout: number | null = null; // Timeout to control delayed magnifier display

  /**
   * This is the adjusted width of the image in the correct aspect ratio in relation to the `maxHeight` property.
   */
  maxWidth: number | null = this.calcImage()?.width;

  /**
   * Per default don't show a spacer left and right to the image.
   */
  isSpacer = false;

  /**
   * generates a randoum Uid for this component
   * If its mounted on the dom each MImageMarker component has a unique id that helps by rezing
   */
  generateComponentUid(): string {
    const template = [1e7, -1e3, -4e3, -8e3, -1e11].join("");

    const replaceFunction = (c: string): string => {
      const randomValue = crypto.getRandomValues(new Uint8Array(1))[0];
      const newValue = (parseInt(c) ^ (randomValue & (15 >> (parseInt(c) / 4)))).toString(16);
      return newValue;
    };

    return template.replace(/[018]/g, replaceFunction);
  }

  /**
   * unique id for marker image html elemt to allow to have multile times used this component
   */
  markerImage = this.generateComponentUid();

  /**
   * When image is loaded we have the "real size" of the image.
   * Rerender the UI elements and set the image state to loaded.
   */
  async onImageLoad() {
    await this.delay(100);
    this.onResize();
    this.imageLoaded = true;
  }

  /**
   * Add a spacer when the image touches the max height
   */
  calcSpacer() {
    const imageSize = this.calcImage();
    return imageSize.height == this.maxHeight;
  }

  calcMarker() {
    this.$log.debug(this.markers);
    return this.markers.map(marker => {
      return {
        ...marker,
        style: this.calcMarkerStyle(marker) || ""
      } as IMarkerAbsolute;
    });
  }

  /**
   * Resizes the image and markers, forces markers to rerender
   */
  onResize() {
    this.maxWidth = this.calcImage().width;
    this.isSpacer = this.calcSpacer();
    this.markersStyled = this.calcMarker();

    this.$log.debug(this.maxWidth, this.isSpacer);
    this.forceUpdate();
  }

  beforeDestroy() {
    if (this.magnifierTimeout) {
      clearTimeout(this.magnifierTimeout);
    }
  }

  /**
   * Forces the image markers to recalculate
   */
  forceUpdate() {
    this.$forceUpdate();
  }

  /**
   * Watch for changes in the image source
   */
  @Watch("src")
  onSrcChange() {
    this.imageLoaded = false;
    this.$log.debug("Image changed");
  }

  /**
   * Watch for changes in the image source
   */
  @Watch("markers", { deep: true })
  onMarkersChange() {
    this.onResize();
    this.$log.debug("Markers changed");
  }

  get isDebug() {
    return ConfigModule.debug;
  }

  /**
   * Calculate the markerDiameter based on size
   */
  get markerDiameter() {
    switch (this.markerSize) {
      case "x-small":
        return 10;
      case "small":
        return 15;
      case "medium":
        return 20;
      case "large":
        return 30;
      default:
        return 20;
    }
  }

  /**
   * Calculate the markerFontsize based on markerSize
   */
  get markerFontSize() {
    switch (this.markerSize) {
      case "small":
        return "xx-small";
      case "medium":
        return "xx-small";
      case "large":
        return "small";
      default:
        return "xx-small";
    }
  }

  /**
   * Hide text if markerSize is x-small
   */
  get showText() {
    return this.markerSize !== "x-small";
  }

  /**
   * Get the marker positions and calculate the marker style
   */
  markersStyled: IMarkerAbsolute[] = [];

  /**
   * get image class dynamicly
   */
  get imageClass() {
    let c = this.deactivated ? "image-deactivated" : "image-activated";
    c += this.isDebug ? " grey darken-4" : "";

    return c;
  }

  /**
   * get marker class dynamicly
   */
  get markerClass() {
    const c = this.deactivated ? "marker-deactivated" : "marker-activated";

    return c;
  }

  /**
   * Calculates the image ratio based on the "natural" height and width to the limiting size of the container that displays the image.
   * We could have used the meta information of the image, now it's independent and calculated at run time on the actual downloaded image size.
   */
  getRatio() {
    const { width, height } = this.getImageContainerSize();

    const imageSize = this.getImageSize();

    if (!imageSize) {
      return 1;
    }

    return Math.min(width / imageSize.width, height / imageSize.height);
  }

  /**
   * Calculates the image size based on the ratio determined in `getRatio`. To display the image as big as possible limited by the image container.
   */
  calcImage() {
    const imageSize = this.getImageSize();
    if (!imageSize) {
      return { width: 0, height: 0 };
    }

    const ratio = this.getRatio();

    const width = imageSize.width * ratio;
    const height = imageSize.height * ratio;

    this.$log.debug(width, height, ratio);

    return { width, height };
  }

  /**
   * The container of the image. <v-img> from vuetify.
   */
  get imageContainer() {
    return (this.$refs[this.markerImage] as unknown) as VImage;
  }

  /**
   * Determines the size of the container of the image with a fixed max height provided by the user.
   */
  getImageContainerSize() {
    const canvas = this.$refs.imageContainer as Element;
    this.$log.debug(canvas?.clientWidth, canvas?.clientHeight);

    return { width: canvas?.clientWidth, height: this.maxHeight };
  }

  /**
   * The actual size of the image that was downloaded.
   */
  getImageSize() {
    const image = (this.$refs[this.markerImage] as unknown) as VImage;

    if (image) {
      const element = image.image as HTMLImageElement;

      if (!element) {
        return undefined;
      }

      this.$log.debug(this.markerImage, element.naturalWidth, element.naturalHeight);

      return { height: element.naturalHeight, width: element.naturalWidth };
    }
  }

  /**
   * calculates the marker style
   * @param marker
   */
  calcMarkerStyle(marker: IMarker) {
    const ref = this.$refs[this.markerImage];
    if (!ref) {
      this.$log.error("Image not found");
      return;
    }

    const imageSize = this.calcImage();
    const imageContainerSize = this.getImageContainerSize();

    const top = marker.top * imageSize.height;
    const left = marker.left * imageSize.width;

    this.$log.debug({
      marker: marker.text,
      marker_relative_position_top: `${Math.round(marker.top * 100)} %`,
      marker_relative_position_left: `${Math.round(marker.left * 100)} %`,
      image_image_height: imageSize.height,
      image_image_width: imageSize.width,
      image_marker_top: marker.top * imageSize.height,
      image_marker_left: marker.left * imageSize.width,
      outer_marker_top: top,
      outer_marker_left: left,
      outer_container_height: imageContainerSize.height,
      outer_container_width: imageContainerSize.width
    });

    const markerColor = marker.color || "#4283ff";

    return `
      position: absolute;
      top: ${top}px;
      left: ${left}px;
      transform: translate(-50%, -50%);
      width: ${this.markerDiameter}px;
      height: ${this.markerDiameter}px;
      font-size: ${this.markerFontSize};
      background-color: ${markerColor};
    `;
  }

  addMarker(event: MouseEvent | TouchEvent) {
    if (this.deactivated) {
      return;
    }

    const ref = this.$refs[this.markerImage] as Element;
    if (!ref) {
      this.$log.error("Image not found");
      return;
    }

    this.$log.debug(event);
    this.$log.debug(ref);

    const imageSize = this.calcImage();
    const imageContainerSize = this.getImageContainerSize();

    let mouseXPos = 0;
    let mouseYPos = 0;

    if (event instanceof MouseEvent) {
      mouseXPos = event.offsetX;
      mouseYPos = event.offsetY;
    } else if (event instanceof TouchEvent && event.changedTouches.length > 0) {
      /**
       * @see https://stackoverflow.com/a/33756703/4986254
       */
      const rect = (event?.target as HTMLElement).getBoundingClientRect();

      mouseXPos = event.changedTouches[0].clientX - rect.left;
      mouseYPos = event.changedTouches[0].clientY - rect.top;
    }

    const top = mouseYPos / imageSize.height;
    const left = mouseXPos / imageSize.width;

    this.$log.debug({
      marker: "new",
      marker_relative_position_top: `${Math.round(top * 100)} %`,
      marker_relative_position_left: `${Math.round(left * 100)} %`,
      image_image_height: imageSize.height,
      image_image_width: imageSize.width,
      image_marker_top: top * imageSize.height,
      image_marker_left: left * imageSize.width,
      outer_marker_top: top,
      outer_marker_left: left,
      outer_container_height: imageContainerSize.height,
      outer_container_width: imageContainerSize.width
    });

    if (top > 1 || left > 1 || top < 0 || left < 0) {
      this.$log.debug("Marker outside image");
      return;
    }

    this.markerCreated({ top, left });
  }

  /**
   * Event that is fired if a marker is created
   * @param marker
   */
  markerCreated(marker: IMarker | undefined) {
    if (marker) {
      this.$emit("markerCreated", marker);
    }
  }

  /**
   * Event that is fired if a marker is clicked
   * @param marker
   */
  markerClicked(marker: IMarker | undefined) {
    if (!this.deactivated) {
      return;
    }
    if (marker) {
      this.$emit("markerClicked", marker);
    }
  }

  markerHover(marker: IMarker | undefined) {
    if (!this.deactivated) {
      return;
    }
    if (marker) {
      this.$emit("markerHover", marker);
    }
  }

  /**
   * Handles mouse down to trigger magnifier after a delay
   */
  onMouseDown(event: MouseEvent) {
    this.handlePointerStart(event);
  }

  /**
   * Handles touch start to trigger magnifier after a delay
   */
  onTouchStart(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.handlePointerStart(event);
  }

  /**
   * Shared logic for pointer start events
   */
  handlePointerStart(event: MouseEvent | TouchEvent) {
    if (this.deactivated) {
      return;
    }
    this.magnifierTimeout = window.setTimeout(() => {
      this.showMagnifier = true;
      if (event instanceof MouseEvent) {
        this.onMouseMove(event);
      } else if (event instanceof TouchEvent) {
        this.onTouchMove(event);
      }
    }, 500); // Delay showing magnifier by 0.5 seconds
  }

  /**
   * Handles mouse up to hide the magnifier
   */
  onMouseUp(event: MouseEvent) {
    this.handlePointerEnd();
    this.addMarker(event);
  }

  /**
   * Handles touch end to hide the magnifier
   */
  onTouchEnd(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.handlePointerEnd();
    this.addMarker(event);
  }

  /**
   * Shared logic for pointer end events
   */
  handlePointerEnd() {
    if (this.magnifierTimeout) {
      clearTimeout(this.magnifierTimeout);
    }
    this.showMagnifier = false;
  }

  /**
   * Updates magnifier position and background on mouse movement
   * @param event
   */
  onMouseMove(event: MouseEvent) {
    this.updateMagnifierPosition(event);
  }

  /**
   * Updates magnifier position and background on touch movement
   * @param event
   */
  onTouchMove(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.updateMagnifierPosition(event);
  }

  // Create a delay function that returns a promise
  delay(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  /**
   * Shared logic to update magnifier position
   * @param event
   */
  updateMagnifierPosition(event: MouseEvent | TouchEvent) {
    if (!this.showMagnifier || this.deactivated) {
      return;
    }

    const canvas = document.getElementById(this.markerImage);
    if (!canvas) {
      this.$log.error("Image not found");
      return;
    }

    const rect = canvas.getBoundingClientRect();

    let mouseXPos = 0;
    let mouseYPos = 0;
    let xAbsolut = 0;

    if (event instanceof MouseEvent) {
      xAbsolut = event.clientX;
      mouseXPos = event.clientX - rect.left;
      mouseYPos = event.clientY - rect.top;
    } else if (event instanceof TouchEvent && event.touches.length > 0) {
      xAbsolut = event.touches[0].clientX;
      mouseXPos = event.touches[0].clientX - rect.left;
      mouseYPos = event.touches[0].clientY - rect.top;
    }

    this.magnifierPosition.x = mouseXPos;
    this.magnifierPosition.y = mouseYPos;

    const windowWidth = window.innerWidth;

    /**
     * Limit the max size of the magnifier
     */
    if (windowWidth / 2 < 200) {
      this.magnifierSize = windowWidth / 2 - 10;
    } else {
      this.magnifierSize = 200;
    }

    /**
     * calculate the xOffset (left or right)
     */
    if (xAbsolut < windowWidth / 2) {
      /**
       * magnifier position is left
       */
      return (this.magnifierPosition.xOffset = -1);
    } else {
      /**
       * magnifier position is right
       */
      return (this.magnifierPosition.xOffset = 1);
    }
  }

  /**
   * Computes the magnifier style dynamically
   */
  get magnifierStyle() {
    const canvas = document.getElementById(this.markerImage);
    const rect = canvas ? canvas.getBoundingClientRect() : { width: 0, height: 0 };

    return {
      position: "absolute",
      top: `${this.magnifierPosition.y - this.magnifierSize / 2}px`,
      left: `${this.magnifierPosition.x - (this.magnifierPosition.xOffset * this.magnifierSize) / 2}px`,
      width: `${this.magnifierSize}px`,
      height: `${this.magnifierSize}px`,
      borderRadius: "50%",
      border: "3px solid #ccc",
      boxShadow: "0 0 5px #000",
      pointerEvents: "none",
      backgroundColor: "#fff", // Set to white
      backgroundImage: `url(${this.src})`,
      backgroundSize: `${rect.width * this.magnificationFactor}px ${rect.height * this.magnificationFactor}px`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${-(this.magnifierPosition.x * this.magnificationFactor - this.magnifierSize / 2)}px`,
      backgroundPositionY: `${-(this.magnifierPosition.y * this.magnificationFactor - this.magnifierSize / 2)}px`
    };
  }
}
