
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldCreateDtoHelper, CustomFieldEnum, CustomFieldModule } from "@/store/modules/custom-field.store";
import { Component, Vue, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { handleError } from "@/lib/utility/handleError";
import { ConflictException } from "@/lib/exceptions/http";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class CustomFieldMixin extends Vue {
  isLoading = false;

  customField: MrfiktivCustomFieldViewModelGen | null = null;

  isDeleteDialogActive = false;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  setCustomField(customField: MrfiktivCustomFieldViewModelGen | null) {
    this.customField = customField ? { ...customField } : null;
  }

  @Watch("customField", { deep: true })
  ensureConfigurationValues() {
    if (
      [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(
        this.customField?.type as CustomFieldEnum
      ) &&
      !this.customField?.configuration?.values
    ) {
      this.customField = {
        ...this.customField,
        key: this.customField?.key ?? "",
        id: this.customField?.id ?? "",
        label: this.customField?.label ?? "",
        partnerId: this.customField?.partnerId ?? "",
        name: this.customField?.name ?? "",
        type: this.customField?.type as CustomFieldEnum,
        configuration: {
          values: []
        }
      };
    }
  }

  async update() {
    try {
      this.isLoading = true;

      if (this.customField) {
        const customFieldId = this.customField.id;
        const partnerId = this.partnerId;
        const data = new CustomFieldCreateDtoHelper(this.customField);

        const customField = await CustomFieldModule.update({
          partnerId,
          customFieldId,
          data
        });
        this.setCustomField(customField);

        CustomFieldModule.replaceInList(this.customField);

        this.$toast.success("👍");
      }
    } catch (e) {
      if (e instanceof ConflictException) {
        this.$toast.error(this.$t("views.CustomFieldList.conflict"));
      } else {
        handleError(e);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async onDelete() {
    const customField = this.customField;
    const customFieldId = customField?.id;

    if (!customFieldId) {
      this.setCustomField(null);
      return;
    }

    this.isDeleteDialogActive = true;
  }

  async confirmDeletion() {
    try {
      this.isLoading = true;
      const customField = this.customField;
      const customFieldId = customField?.id;
      const partnerId = this.partnerId;

      if (customFieldId) {
        const deleted = await CustomFieldModule.delete({
          partnerId,
          customFieldId
        });
        CustomFieldModule.removeInList(deleted);
        this.$toast.success("🗑️👍");
      }

      this.setCustomField(null);

      this.isDeleteDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async create(createCustomFieldDto: CustomFieldCreateDtoHelper) {
    let created: MrfiktivCustomFieldViewModelGen | null = null;
    try {
      this.isLoading = true;

      const partnerId = this.partnerId;

      created = await CustomFieldModule.create({ partnerId, data: createCustomFieldDto });

      CustomFieldModule.addToList(created);
      this.setCustomField(created);

      this.$toast.success("👍");
    } catch (e) {
      if (e instanceof ConflictException) {
        this.$toast.error(this.$t("views.CustomFieldList.conflict"));
      } else {
        handleError(e);
      }
    } finally {
      this.isLoading = false;
    }

    return created;
  }
}
