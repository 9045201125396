

















































import { Component, Prop } from "vue-property-decorator";
import FileUploadPreview from "../utility/FileUploadPreview.vue";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { AssetEnum } from "@/lib/AssetRepository";
import { MrfiktivCreateReportAsPartnerDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportModule } from "@/store/modules/report.store";
import { PartnerModule } from "@/store/modules/partner";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    Card,
    FileUploadPreview
  }
})
export default class PartnerReportCardImages extends PartnerReportCardGeneral {
  /**
   * This setting will open the back camera on a mobile device
   */
  @Prop({ default: false })
  isCaptureEnvironment?: boolean;

  /**
   * Option to not show the dropdown for the image type
   */
  @Prop({ default: false })
  hideSelect?: boolean;

  showRemove = false;

  get reportImageTypes() {
    return Object.values(ReportImageType);
  }

  get AssetEnum() {
    return AssetEnum;
  }

  displayImages: IImageUploaded[] = [];

  imageType = ReportImageType.damage;

  get images() {
    const registrations: string[] = this.reportDto.images?.registrations ?? [];
    const cockpits: string[] = this.reportDto.images?.cockpits ?? [];
    const damages: string[] = this.reportDto.images?.damages ?? [];
    const damagesDetail: string[] = this.reportDto.images?.damagesDetail ?? [];
    const overviews: string[] = this.reportDto.images?.overviews ?? [];

    if (!(this.reportDto as CreateReportAsPartnerDto)) {
      this.reportDto = {
        ...this.reportDto,
        images: { registrations, cockpits, damages, damagesDetail, overviews }
      } as CreateReportAsPartnerDto;
    }

    return (this.reportDto as MrfiktivCreateReportAsPartnerDtoGen).images?.overviews ?? [];
  }

  set images(images: string[]) {
    const overviews = (this.reportDto as MrfiktivCreateReportAsPartnerDtoGen).images?.overviews ?? [];
    overviews?.splice(0, overviews.length, ...images);
  }

  handleDelete(file: File) {
    const index = this.displayImages.findIndex(f => f.file.name === file.name);
    this.displayImages.splice(index, 1);
    (this.reportDto as MrfiktivCreateReportAsPartnerDtoGen).images?.overviews.splice(index, 1);
  }

  async handleUpload(file: File) {
    this.$emit("setIsLoading", true);

    try {
      ReportModule.setPartnerName(PartnerModule.partner.companyUsername);

      const image = {
        file,
        type: this.imageType,
        categoryKey: ""
      };
      const imageId = await ReportModule.addImage(image);
      this.displayImages.push({ isLoading: false, isUploaded: false, uploadId: imageId.uploadId, ...image });

      if (!this.reportDto.images) {
        this.reportDto.images = {
          registrations: [],
          cockpits: [],
          damages: [],
          damagesDetail: [],
          overviews: []
        };
      }

      (this.reportDto as MrfiktivCreateReportAsPartnerDtoGen).images?.overviews.push(imageId.uploadId);
    } catch (error) {
      handleError(error);
    } finally {
      this.$emit("setIsLoading", false);
    }
  }

  reset() {
    this.displayImages = [];
  }
}
