






































































































































































































































































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import {
  MrfiktivAddressWithGeoGen,
  MrfiktivCreateAccidentDetailsDtoGen,
  MrfiktivUpdateReportDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Ticket } from "@/models/ticket.entity";
import { Component, Prop, Watch } from "vue-property-decorator";
import PartnerReportCardAccidentDetails from "../partner/PartnerReportCardAccidentDetails.vue";
import PartnerReportCardAddress from "../partner/PartnerReportCardAddress.vue";
import PartnerReportCardBody from "../partner/PartnerReportCardBody.vue";
import PartnerReportCardContact from "../partner/PartnerReportCardContact.vue";
import PartnerReportCardDamage from "../partner/PartnerReportCardDamage.vue";
import PartnerReportCardDatePreference from "../partner/PartnerReportCardDatePreference.vue";
import PartnerReportCardGeneral from "../partner/PartnerReportCardGeneral.vue";
import PartnerReportCardImages from "../partner/PartnerReportCardImages.vue";
import PartnerReportCardLeasing from "../partner/PartnerReportCardLeasing.vue";
import PartnerReportCardMessage from "../partner/PartnerReportCardMessage.vue";
import PartnerReportCardName from "../partner/PartnerReportCardName.vue";
import PartnerReportCardPolice from "../partner/PartnerReportCardPolice.vue";
import PartnerReportCardRegistrationAddress from "../partner/PartnerReportCardRegistrationAddress.vue";
import PartnerReportCardRegistrationDate from "../partner/PartnerReportCardRegistrationDate.vue";
import PartnerReportCardRegistrationMainInspection from "../partner/PartnerReportCardRegistrationMainInspection.vue";
import PartnerReportCardRegistrationName from "../partner/PartnerReportCardRegistrationName.vue";
import PartnerReportCardRegistrationVehicle from "../partner/PartnerReportCardRegistrationVehicle.vue";
import PartnerReportCardWitnesses from "../partner/PartnerReportCardWitnesses.vue";
import PartnerReportCardInsurances from "../partner/PartnerReportCardInsurances.vue";
import Debug from "../utility/Debug.vue";
import VehicleApiSelection from "../utility/VehicleApiSelection.vue";
import RegistrationForm from "../utility/RegistrationForm.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

/**
 * Dto to create accident details
 */
export class AccidentDetailsDto implements MrfiktivCreateAccidentDetailsDtoGen {
  role: ReportRoleEnum;
  date: string;
  comment?: string;
  address: MrfiktivAddressWithGeoGen;

  constructor() {
    this.address = {
      city: "",
      geo: { lat: 0, lng: 0 },
      state: "",
      street: "",
      zip: "",
      countryCode: CountryCodeEnum.germany
    };
    this.comment = "";
    this.date = new Date().toISOString();
    this.role = ReportRoleEnum.UNCLEAR;
  }
}

export interface ICreateReportFormActiveTabs {
  position: boolean;
  report: boolean;
  date: boolean;
  contact: boolean;
  registration: boolean;
  accident: boolean;
  witness: boolean;
  leasing: boolean;
  police: boolean;
  preExisting: boolean;
  insurance: boolean;
  images: boolean;
  body: boolean;
  message: boolean;
}

@Component({
  components: {
    PartnerReportCardAddress,
    PartnerReportCardContact,
    PartnerReportCardDamage,
    PartnerReportCardDatePreference,
    PartnerReportCardGeneral,
    PartnerReportCardName,
    PartnerReportCardAccidentDetails,
    PartnerReportCardRegistrationAddress,
    PartnerReportCardRegistrationDate,
    PartnerReportCardRegistrationName,
    PartnerReportCardRegistrationMainInspection,
    PartnerReportCardRegistrationVehicle,
    PartnerReportCardWitnesses,
    PartnerReportCardLeasing,
    PartnerReportCardPolice,
    PartnerReportCardImages,
    PartnerReportCardMessage,
    PartnerReportCardBody,
    PartnerReportCardInsurances,
    VehicleApiSelection,
    RegistrationForm,
    Debug
  }
})
export default class CreateReportForm extends mixins(PermissionMixin) {
  @Prop()
  active!: ICreateReportFormActiveTabs;

  @Prop()
  private dto!: MrfiktivUpdateReportDtoGen | CreateReportAsPartnerDto;

  @Prop()
  value!: boolean;

  get reportDto() {
    return this.dto;
  }

  set reportDto(value) {
    this.$emit("update:dto", value);
  }

  get isValid() {
    return this.value;
  }

  set isValid(v: boolean) {
    this.$emit("input", v);
  }

  loadingVehicle = false;

  validity = {
    isCardGeneralValid: true,
    isDateValid: true,
    isCardDamageValid: true,
    isCardDatePreferenceValid: true,
    isReportCardNameValid: true,
    isReportCardAddressValid: true,
    isReportCardContactValid: true,
    isReportCardRegistrationDateValid: true,
    isReportCardRegistrationAddressValid: true,
    isReportCardRegistrationNameValid: true,
    isReportCardRegistrationMainInspectionValid: true,
    isReportCardRegistrationVehicleValid: true,
    isReportCardAccidentDetails: true,
    isReportCardWitnessValid: true,
    isReportCardLeasingValid: true,
    isReportCardPoliceValid: true,
    isInsuranceValid: true,
    isTicketValid: true,
    isReportCardRegistrationVehicleLinkValid: true
  };

  ticket = new Ticket();

  vehicle: IVehicle | null = null;

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get isCardGeneralValid() {
    return this.validity.isCardGeneralValid;
  }

  get isDateValid() {
    return this.validity.isDateValid;
  }

  get isDamageValid() {
    return this.validity.isCardDamageValid && this.validity.isCardDatePreferenceValid;
  }

  get isContactValid() {
    return (
      this.validity.isReportCardNameValid &&
      this.validity.isReportCardAddressValid &&
      this.validity.isReportCardContactValid
    );
  }

  get isRegistrationValid() {
    return (
      this.validity.isReportCardRegistrationDateValid &&
      this.validity.isReportCardRegistrationAddressValid &&
      this.validity.isReportCardRegistrationNameValid &&
      this.validity.isReportCardRegistrationMainInspectionValid &&
      this.validity.isReportCardRegistrationVehicleValid
    );
  }

  get isAccidentDetailValid() {
    return this.validity.isReportCardAccidentDetails;
  }

  get isWitnessDetailValid() {
    return this.validity.isReportCardWitnessValid;
  }

  get isLeasingValid() {
    return this.validity.isReportCardLeasingValid;
  }

  get isPoliceValid() {
    return this.validity.isReportCardPoliceValid;
  }

  get isInsuranceValid() {
    return this.validity.isInsuranceValid;
  }

  get isMoreThanOneTabOpen() {
    let countTabs = 0;
    for (const value of Object.values(this.active)) {
      if (value) {
        countTabs++;
      }
    }

    return countTabs > 1;
  }

  @Watch("validity", { deep: true })
  emitValidation() {
    this.isValid =
      this.isCardGeneralValid &&
      this.isDateValid &&
      this.isDamageValid &&
      this.isContactValid &&
      this.isRegistrationValid &&
      this.isAccidentDetailValid &&
      this.isWitnessDetailValid &&
      this.isLeasingValid &&
      this.isPoliceValid &&
      this.isInsuranceValid;
  }

  async mounted() {
    await this.fetchVehicle();
  }

  async fetchVehicle() {
    const vehicleId = this.reportDto.vehicleId;
    if (vehicleId && this.canReadVehicle) {
      try {
        this.loadingVehicle = true;

        // get vehicle from store or backend
        let vehicle = VehicleModule.maps.id.get(vehicleId)[0];
        if (!vehicle) {
          vehicle = await new Vehicle({
            partnerId: this.partnerId,
            id: vehicleId
          }).fetch();
        }
        this.vehicle = vehicle;
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.loadingVehicle = false;
      }
    }
  }
}
