











































































































import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import ImagePositionSelector from "@/views/sign/ImagePositionSelector.vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: { ImagePositionSelector }
})
export default class PdfViewer extends DarkModeHighlightMixin {
  /**
   * The pdf file to display
   */
  @Prop()
  pdf!: string | Uint8Array;

  @Prop({ default: false })
  horizontal!: boolean;

  @Prop({ default: true })
  vertical!: boolean;

  @Prop({ default: "100%" })
  height!: string;

  /**
   * A list of promises that each return a page of the pdf as an image
   */
  images: string[] = [];

  currentPage = 0;

  isLoading = false;

  /**
   * Handles swiping gestures
   */
  get gestures() {
    return {
      up: () => this.openNextPage(),
      left: () => this.openNextPage(),
      right: () => this.openPreviousPage(),
      down: () => this.openPreviousPage()
    };
  }

  /**
   * Converts each page of the pdf to an image
   */
  async mounted() {
    if (!this.pdf) {
      return;
    }

    this.isLoading = true;
    try {
      const images = await convertPdfToImg(this.pdf);
      this.images.splice(0, this.images.length, ...images);
      this.isLoading = false;

      document.addEventListener("keyup", this.handleKeyDown);
      this.scrollIntoView();
    } catch (e) {
      this.$log.error(e);
    }
  }

  /**
   * removes key event listener
   */
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyDown);
  }

  /**
   * navigate the pdf using keys
   */
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "ArrowLeft") {
      this.openPreviousPage();
    }

    if (event.key === "ArrowRight") {
      this.openNextPage();
    }
  }

  openPreviousPage() {
    this.currentPage = (this.currentPage - 1 + this.images.length) % this.images.length;
  }

  openNextPage() {
    this.currentPage = (this.currentPage + 1) % this.images.length;
  }

  getId(index: number) {
    return `pdfimage${index}`;
  }

  @Watch("currentPage")
  scrollIntoView() {
    const element = document.getElementById(this.getId(this.currentPage));
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}
