
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivUpdateTicketDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { Component, Vue } from "vue-property-decorator";
import { Ticket, ITicket } from "@/models/ticket.entity";
import { TicketDataAccessLayer } from "@/store/modules/access-layers/ticket.access-layer";
import { TicketModule } from "@/store/modules/ticket.store";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { CustomFieldValue, ICustomFieldValue } from "@/models/custom-field-value.entity";

/**
 * Mixin with stuff shared by views that display details of a ticket
 */
@Component({
  components: {},
  filters: {}
})
export default class TicketCrudMixin extends Vue {
  ticket: ITicket | null = null;

  loadingUpdate = false;

  loadingTicket = false;

  partnerId = "";
  ticketNumber = "";
  ticketId = "";
  viewId = 0;

  async getTicketByNumber(ticketNumber: number) {
    const partnerId = this.partnerId;

    try {
      this.loadingTicket = true;
      this.ticket = null;
      this.ticket = await new Ticket({ number: ticketNumber, partnerId }).fetch();
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingTicket = false;
    }

    return this.ticket;
  }

  /**
   * Method to updated ticket
   * @param {Ticket} ticket the information of the ticket to update
   * doesnt update custom values or state
   */
  async updateTicket(updateDto: MrfiktivUpdateTicketDtoGen, ticketId?: string, partnerId?: string) {
    try {
      const _partnerId = partnerId ?? this.partnerId;
      const _ticketId = ticketId ?? this.ticket?.id ?? this.ticketId;

      this.$log.debug(updateDto);

      if (!_ticketId) {
        this.$log.error("TicketId not set");
        return;
      }

      this.loadingUpdate = true;

      this.ticket = await (
        TicketModule.maps.id.get(_ticketId)[0] ?? (await new Ticket({ id: _ticketId, partnerId: _partnerId }))
      ).updatePartial(updateDto);
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  /**
   * Method to updated ticket
   * @param ticket
   */
  async updateTicketStatus(ticket: ITicket) {
    try {
      const ticketId = ticket.id || this.ticketId || this.ticket?.id;

      if (!ticketId) {
        this.$toast.error("TicketId not set");
        return;
      }

      if (!this.partnerId) {
        this.$toast.error("PartnerId not set");
        return;
      }

      await this.updateTicket({ state: ticket.state });

      if (ticket.state === TicketStatusEnum.CLOSED) {
        this.$toast.success(this.$t("common.BackendResourceEnum.click.success.ticket"));
      } else {
        this.$toast.success("👍");
      }

      if (this.ticket) {
        const activity = await ActivityLogModule.create({
          partnerId: this.partnerId,
          data: {
            source: {
              refType: BackendResourceEnum.TICKET,
              refId: ticketId
            },
            actionType: ActionEnum.UPDATE,
            activity: ActivityTypeEnum.UPDATE_PROGRESS_STATUS,
            comment: this.ticket.state
          }
        });
        ActivityLogModule.addToList(activity);
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  /**
   * Update custom values
   */
  async updateCustomFieldValues(values: ICustomFieldValue[]) {
    this.$log.debug("updateCustomFieldValues", JSON.stringify(values));

    try {
      const partnerId = this.partnerId;
      const ticketId = this.ticketId || this.ticket?.id;
      const data: MrfiktivUpdateTicketDtoGen = {
        values: CustomFieldValue.buildCustomFieldValuesDto(
          values.map(v => {
            const customFieldValue: ICustomFieldValue = { id: v.id, value: v.value };

            if (v.timezone) {
              customFieldValue.timezone = v.timezone;
            }

            return new CustomFieldValue(customFieldValue);
          })
        )
      };

      if (!ticketId) {
        this.$log.error("TicketId not set");
        return;
      }

      this.loadingUpdate = true;

      let ticket = this.ticket;

      if (!ticket) {
        const found = TicketDataAccessLayer.get(ticketId);
        if (found) {
          this.$log.debug("Use stored ticket");
          ticket = found;
        }

        if (!ticket) {
          this.$log.debug("Fetch ticket again???");
          ticket = await new Ticket({ id: ticketId, partnerId: partnerId }).fetch();
        }
      }

      this.$log.debug("before", JSON.stringify(ticket.values));

      ticket.values = CustomFieldValue.buildCustomFieldValues(data.values ?? []);

      await ticket.update();
      this.$log.debug("after", JSON.stringify(ticket.values));
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  /**
   * Delete ticket for a partner.
   * Return true on success and false on failure.
   * @param ticket the ticket to be deleted
   */
  async deleteTicket(ticket: ITicket): Promise<boolean> {
    try {
      const ticketId = ticket?.id;

      if (!ticketId) {
        return false;
      }

      this.loadingUpdate = true;

      await ticket.delete();

      return true;
    } catch (e) {
      handleError(e);
      return false;
    } finally {
      this.loadingUpdate = false;
    }
  }

  async createAddAssigneeActivity(newAssignees: string[]) {
    await this.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, newAssignees);
  }

  async createDeleteAssigneeActivity(newAssignees: string[]) {
    await this.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, newAssignees);
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  private async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    const ticketId = this.ticketId || this.ticket?.id;

    if (!ticketId) {
      this.$toast.error("TicketId not set");
      return;
    }

    if (!this.partnerId) {
      this.$toast.error("PartnerId not set");
      return;
    }

    const source = {
      refType: BackendResourceEnum.TICKET,
      refId: ticketId
    };
    const partnerId = this.partnerId;

    await ActivityLogModule.createAssigneeActivity({
      partnerId,
      source,
      newAssignees,
      activityType
    });
  }
}
