
























































import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";
import MImageMarker from "../utility/mmmint/MImageMarker.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: { PartnerReportUpdateReportDialog, DamageLocatorCombined, MImageMarker }
})
export default class ReportDamageLocationTimeLineElement extends mixins(PermissionMixin) {
  readonly ReportUpdateTabEnum = ReportUpdateTabEnum;

  @Prop({ default: "primary" })
  color!: string;

  @Prop({ default: "#6fb38a" })
  clickedColor!: string;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  renderComponent = true;

  get getDamageLocatorWidth() {
    return "250px";
  }

  get damageLocation() {
    this.forceRerender();
    return this.report?.damage;
  }

  get hasDamageLocation(): boolean {
    return this.damageLocation?.length > 0 || this.report.position != undefined;
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }
}
