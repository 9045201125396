var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-dialog', {
    attrs: {
      "auto": "",
      "offset-y": "",
      "flat": "",
      "width": "600px"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.close.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.send.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.isDialogActive,
      callback: function callback($$v) {
        _vm.isDialogActive = $$v;
      },
      expression: "isDialogActive"
    }
  }, [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToPdrDialog.title")) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToPdrDialog.cancel")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToPdrDialog.confirm")))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }