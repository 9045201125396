var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_vm.report.handover && _vm.report.handover.handoverId ? _c('v-timeline-item', {
    attrs: {
      "small": "",
      "icon": "mdi-handshake",
      "fill-dot": ""
    }
  }, [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.handover")) + " ")])]) : _vm._e(), _vm.report.handover && _vm.report.handover.handoverId ? _c('v-timeline-item', {
    attrs: {
      "hide-dot": ""
    }
  }, [_c('div', {
    staticClass: "mx-n4 mt-n5"
  }, [_vm.loadingHandover ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item"
    }
  }) : _vm.handover ? _c('refs-handover', {
    attrs: {
      "item": _vm.handover
    },
    on: {
      "openDetail": _vm.goToHandoverDetail
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1), _c('div', [_vm.report.handover && _vm.report.handover.handoverId && _vm.report.handover.foundInInspection ? _c('v-timeline-item', {
    attrs: {
      "small": "",
      "icon": "mdi-binoculars",
      "fill-dot": ""
    }
  }, [_c('div', {
    staticClass: "mb-1",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.inspection")) + " ")]), _c('v-spacer'), _vm.canUpdateReport && _vm.handover && _vm.handover.inspections && _vm.handover.inspections.length > 1 ? _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "info",
      "x-small": "",
      "elevation": 0,
      "loading": _vm.isInspectionUpdateLoading
    },
    on: {
      "click": _vm.openUpdateInspectionDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm._e()], 1)]) : _vm._e(), _vm.report.handover && _vm.report.handover.handoverId && _vm.report.handover.foundInInspection ? _c('v-timeline-item', {
    attrs: {
      "hide-dot": ""
    }
  }, [_c('div', {
    staticClass: "mx-n4 mt-n5"
  }, [_vm.loadingInspection ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item"
    }
  }) : _vm.inspection ? _c('refs-inspection', {
    attrs: {
      "item": _vm.inspection
    },
    on: {
      "openDetail": _vm.goToInspectionDetail
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1), _vm.isUpdateInspectionDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUpdateInspectionDialogActive,
      "loading": _vm.isInspectionUpdateLoading,
      "rightLoading": _vm.isInspectionUpdateLoading,
      "title": _vm.$t('handover.updateInspection'),
      "rightText": _vm.$t('update')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUpdateInspectionDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUpdateInspectionDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isUpdateInspectionDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isUpdateInspectionDialogActive = false;
      },
      "rightClick": _vm.updateInspection
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.handover.inspections,
      "item-text": "text",
      "item-value": "id",
      "return-object": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('refs-inspection', {
          staticStyle: {
            "max-width": "400px"
          },
          attrs: {
            "item": item
          }
        })];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('refs-inspection', {
          staticStyle: {
            "max-width": "400px"
          },
          attrs: {
            "item": item
          }
        })];
      }
    }], null, false, 510903676),
    model: {
      value: _vm.selectedInspection,
      callback: function callback($$v) {
        _vm.selectedInspection = $$v;
      },
      expression: "selectedInspection"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }