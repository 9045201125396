















































































































































































































































import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CustomFieldEnum, CustomFieldHelper, customFieldIconTypeMap } from "@/store/modules/custom-field.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import CustomField from "./CustomField.vue";
import CustomFieldDetailIcon from "./CustomFieldDetailIcon.vue";
import CustomFieldValueChip from "./CustomFieldValueChip.vue";
import draggable from "vuedraggable";
import { MrfiktivCustomFieldConfigurationElementDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {
    TheLayoutPortal,
    PaginatedTable,
    Card,
    ConfirmActionDialog,
    CustomFieldDetailIcon,
    CustomField,
    CustomFieldValueChip,
    Tooltip,
    draggable
  },
  filters: {}
})
export default class CustomFieldForm extends Vue {
  @Prop()
  customField!: CustomFieldHelper;

  @Prop()
  loading!: boolean;

  @Prop()
  value!: boolean;

  @Prop({ default: false })
  isDisabled!: boolean;

  removeUpdateKey = 0;

  newConfigurationValue = "";

  isEditConfigurationDialog = false;

  previewValue = "";

  isPreviewRequired = false;

  editConfigurationValue: null | MrfiktivCustomFieldConfigurationElementDtoGen = null;

  readonly colors = ["blue-grey", "blue", "green", "lime", "yellow darken-1", "orange", "red", "pink", "purple"];

  setEditConfigurationValue(editIndex: number) {
    this.editConfigurationValue = this.customField.configuration?.values[editIndex] ?? null;
  }

  get showConfiguration() {
    return [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(this.customField.type);
  }

  get CustomFieldEnum() {
    return CustomFieldEnum;
  }

  get customFieldValues() {
    return Object.values(CustomFieldEnum);
  }

  get hasPlaceholder() {
    return !(
      this.customField.type === CustomFieldEnum.DATE ||
      this.customField.type === CustomFieldEnum.DATE_TIME ||
      this.customField.type === CustomFieldEnum.BOOLEAN
    );
  }

  get isValid() {
    return this.value;
  }
  set isValid(v: boolean) {
    this.$emit("input", v);
  }

  get name() {
    return this.customField.name;
  }
  set name(v: string) {
    this.customField.name = v;
  }

  get label() {
    return this.customField.label;
  }
  set label(v: string) {
    this.customField.label = v;
  }

  get hint() {
    return this.customField.hint;
  }
  set hint(v: string) {
    this.customField.hint = v;
  }

  get type(): CustomFieldEnum {
    return this.customField.type;
  }
  set type(v: CustomFieldEnum) {
    this.customField.type = v;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get values() {
    if (!this.customField.configuration) {
      this.customField.configuration = { values: [] };
    }

    return this.customField.configuration?.values;
  }

  get requiredCustomFieldRule() {
    if (
      !(
        this.values?.length &&
        [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(this.customField.type)
      )
    ) {
      return [requiredRule()];
    }

    return [];
  }

  getIconForField(type: CustomFieldEnum) {
    return customFieldIconTypeMap.get(type);
  }

  addNewConfiguration() {
    if (!this.customField.configuration) {
      this.customField.configuration = { values: [] };
    }

    this.customField.configuration?.values.push({
      color: this.colors[0],
      description: "",
      value: this.newConfigurationValue
    });

    this.newConfigurationValue = "";
  }

  startEditConfigurationValue(index: number) {
    this.setEditConfigurationValue(index);
    this.isEditConfigurationDialog = true;
  }

  removeConfiguration(index: number) {
    this.removeUpdateKey++;
    this.customField.configuration?.values.splice(index, 1);
  }

  /**
   * Method that takes string makes it lowercase and replaces all spaces with minuses
   * alls special characters are removed multiple spaces or line breaks become one
   * also remove numbers
   */
  makeKey(keyToBe: string) {
    return keyToBe
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  deriveKeyFromName() {
    this.customField.key = this.makeKey(this.customField.name);
  }

  makeKeyKeylike() {
    this.customField.key = this.makeKey(this.customField.key);
  }
}
