



























































































































































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { handleError } from "@/lib/utility/handleError";
import { IDaExportReportDto, IGetFreeSlotDto } from "@/services/mrfiktiv/services/daService";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivDAServiceEventDetailViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

enum SearchOptions {
  DOSSIER_ID,
  NUMBERPLATE
}

enum WindowOptions {
  SEARCH,
  NEW,
  EXISTING
}

@Component({ components: { ConfirmActionDialog } })
export default class SendToDaDialog extends Vue implements IDialog {
  isDialogActive = false;

  isLoadingDaServiceEvents = false;
  resourcesLoading = false;
  eventTypesLoading = false;
  workshopServicesLoading = false;
  freeSlotLoading = false;

  searchOption = SearchOptions.NUMBERPLATE;
  window = WindowOptions.SEARCH;

  numberPlate = "";
  search = "";

  selectionResourceId = Number(ExportModule.daSetup?.resource) ?? NaN;
  selectionEventTypeId = Number(ExportModule.daSetup?.eventType) ?? NaN;
  selectionWorkshopServiceId = Number(ExportModule.daSetup?.workshopService) ?? NaN;
  selectionFreeSlot: string | null | undefined = null;
  selectedServiceEvent: MrfiktivDAServiceEventDetailViewModelGen | null | undefined = null;

  /**
   * look for a dossier id or a numberplate
   */
  get SearchOptions() {
    return SearchOptions;
  }

  /**
   * The window options
   */
  get WindowOptions() {
    return WindowOptions;
  }

  get numberPlateRules() {
    return [numberplateRule()];
  }

  get daResources() {
    return ExportModule.daResources?.resources;
  }

  get daEventTypes() {
    return ExportModule.daEventTypes?.eventTypes;
  }

  get daServiceEvents() {
    return ExportModule.daServiceEvents;
  }

  get daWorkshopServices() {
    return ExportModule.daWorkshopServices?.workshopServices;
  }

  get hasDefaults() {
    return ExportModule.daSetup?.isMandatoryConfiguration;
  }

  get daFreeSlots(): IDateDisplay[] | undefined {
    const freeSlots = ExportModule.daFreeSlot?.freeSlots.map(x => {
      return {
        value: x,
        display: new Date(x).toLocaleString("de-De")
      };
    });

    return freeSlots;
  }

  get hideLeft() {
    return this.window === WindowOptions.SEARCH;
  }

  get hideRight() {
    return this.window === WindowOptions.SEARCH;
  }

  rightClick() {
    switch (this.window) {
      case WindowOptions.SEARCH:
        return () => Promise.resolve();

      case WindowOptions.NEW:
        return this.createServiceEvent();

      case WindowOptions.EXISTING:
        return this.createWorkshopTask();

      default:
        return () => Promise.resolve();
    }
  }

  getFieldText(resource: any) {
    if (!resource) {
      return "";
    }

    const id = resource.id;
    const name = resource.name;

    return `${name} (${id})`;
  }

  get selectedResource() {
    return this.daResources?.find(x => x.id === this.selectionResourceId);
  }

  get selectedEventType() {
    return this.daEventTypes?.find(x => x.id === this.selectionEventTypeId);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  async mounted() {
    this.clearSelection();
    this.numberPlate = PartnerModule.report.numberplate || "";
    this.search = PartnerModule.report.numberplate || "";

    this.resourcesLoading = true;
    this.eventTypesLoading = true;

    try {
      await Promise.all([
        ExportModule.getDaResources(this.partnerId).finally(() => (this.resourcesLoading = false)),
        ExportModule.getDaEventTypes(this.partnerId).finally(() => (this.eventTypesLoading = false)),
        ExportModule.getDaWorkshopServices(this.partnerId).finally(() => (this.workshopServicesLoading = false))
      ]);

      if (this.search) {
        await this.fetchDossiers();
      }
    } catch (error) {
      handleError(error);
    }
  }

  clearSelection() {
    this.selectionResourceId = NaN;
    this.selectionEventTypeId = NaN;
    this.selectionFreeSlot = undefined;
    ExportModule.clearDaFreeSlot();
    ExportModule.clearDaServiceEvents();
  }

  open(): void {
    this.isDialogActive = true;

    if (this.search) {
      this.fetchDossiers();
    }
  }

  close(): void {
    this.clearSelection();
    this.isDialogActive = false;
  }

  switchToNew() {
    this.selectionResourceId = Number(ExportModule.daSetup?.resource);
    this.selectionEventTypeId = Number(ExportModule.daSetup?.eventType);

    if (this.selectionResourceId) {
      this.fetchFreeSlots(`${this.selectionResourceId}`);
    }

    this.window = WindowOptions.NEW;
  }

  selectDossier(item: MrfiktivDAServiceEventDetailViewModelGen) {
    this.selectedServiceEvent = item;
    this.selectionWorkshopServiceId = Number(ExportModule.daSetup?.workshopService);

    this.window = WindowOptions.EXISTING;
  }

  async createWorkshopTask() {
    if (!this.selectedServiceEvent) {
      this.$log.error("Missing service event");

      return;
    }

    const daExportReportDto: IDaExportReportDto = {
      daExportReportMetaDto: {
        serviceEventId: this.selectedServiceEvent.serviceEventId,
        workshopTaskId: `${this.selectionWorkshopServiceId}`
      }
    };

    try {
      const op = await ExportModule.exportReportToDa({
        partnerId: this.partnerId,
        reportId: PartnerModule.report._id,
        daExportReportDto
      });

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToDaDialog.success"));

      await this.addActivity(op.operationId);

      this.close();
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToExternOption.fail"));
    }
  }

  async createServiceEvent() {
    if (!this.selectionResourceId || !this.selectionEventTypeId) {
      this.$log.error("Missing resource or event type");

      return;
    }

    const startDate = this.selectionFreeSlot;

    const daExportReportDto: IDaExportReportDto = {
      numberPlate: this.numberPlate,
      resourceId: this.selectionResourceId,
      eventTypeId: this.selectionEventTypeId,
      startDate: startDate ?? undefined
    };

    try {
      const op = await ExportModule.exportReportToDa({
        partnerId: this.partnerId,
        reportId: PartnerModule.report._id,
        daExportReportDto
      });

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToDaDialog.success"));

      await this.addActivity(op.operationId);

      this.close();
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToExternOption.fail"));
    }
  }

  async addActivity(comment: string) {
    const data: MrfiktivCreateActivityLogDtoGen = {
      source: {
        refType: BackendResourceEnum.REPORT,
        refId: PartnerModule.report.id
      },
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.EXPORT,
      comment: comment
    };
    const activity = await ActivityLogModule.create({ partnerId: this.partnerId, data: data });
    ActivityLogModule.addToList(activity);
  }

  async fetchFreeSlots(resourceId: string) {
    await this.getFreeSlot(resourceId, new Date());

    this.selectionFreeSlot = ExportModule.daFreeSlot?.freeSlots[0];
  }

  async getFreeSlot(resourceId: string, startDate: Date) {
    this.freeSlotLoading = true;

    const freeSlotRequest: IGetFreeSlotDto = {
      partnerId: this.partnerId,
      resourceId: Number(resourceId),
      startDate: startDate.toISOString()
    };
    try {
      await ExportModule.getDaFreeSlot(freeSlotRequest).finally(() => (this.freeSlotLoading = false));
    } catch (error) {
      handleError(error);
    }
  }

  async fetchDossiers() {
    if (!this.search) {
      this.$log.error("Search empty");

      return;
    }

    this.isLoadingDaServiceEvents = true;

    try {
      if (this.searchOption === SearchOptions.DOSSIER_ID) {
        await ExportModule.getDaServiceEvent({ serviceEventId: this.search, partnerId: this.partnerId });
      } else {
        await ExportModule.getDaServiceEvents({ licensePlate: this.search, partnerId: this.partnerId });
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingDaServiceEvents = false;
    }
  }
}

interface IDateDisplay {
  value: string;
  display: string;
}
