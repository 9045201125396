/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAdminMessageControllerCreateBatchPayloadGen,
  MrfiktivAdminMessageControllerCreateFromTemplateParamsGen,
  MrfiktivAdminMessageControllerFindAllParamsGen,
  MrfiktivAdminMessageInboundControllerFindAllInboundParamsGen,
  MrfiktivAdminMessageInboundViewModelGen,
  MrfiktivAdminMessageViewModelGen,
  MrfiktivCreateMessageDtoGen,
  MrfiktivCreateTemplateMessageDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateMessageDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Message<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags message
   * @name AdminMessageInboundControllerFindAllInbound
   * @summary Get all messages
   * @request GET:/message-inbound
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivAdminMessageInboundViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageInboundControllerFindAllInbound = (
    query: MrfiktivAdminMessageInboundControllerFindAllInboundParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivAdminMessageInboundViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/message-inbound`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageInboundControllerFind
   * @summary Get a message with download link to content
   * @request GET:/message-inbound/{id}
   * @secure
   * @response `200` `MrfiktivAdminMessageInboundViewModelGen` The found message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageInboundControllerFind = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminMessageInboundViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/message-inbound/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreate
   * @summary Create a message
   * @request POST:/message
   * @secure
   * @response `200` `MrfiktivAdminMessageViewModelGen` The created message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreate = (data: MrfiktivCreateMessageDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/message`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerFindAll
   * @summary Get all system messages
   * @request GET:/message
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivAdminMessageViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerFindAll = (query: MrfiktivAdminMessageControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivAdminMessageViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/message`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreateBatch
   * @summary Create a batch of messages
   * @request POST:/message/batch
   * @secure
   * @response `200` `(MrfiktivAdminMessageViewModelGen)[]` The created messages
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreateBatch = (
    data: MrfiktivAdminMessageControllerCreateBatchPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAdminMessageViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/message/batch`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerFindOneById
   * @summary Get a message
   * @request GET:/message/{id}
   * @secure
   * @response `200` `MrfiktivAdminMessageViewModelGen` The found message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerFindOneById = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/message/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerUpdateOneById
   * @summary Update a message
   * @request PUT:/message/{id}
   * @secure
   * @response `200` `MrfiktivAdminMessageViewModelGen` The updated message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerUpdateOneById = (id: string, data: MrfiktivUpdateMessageDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivAdminMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/message/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreateFromTemplate
   * @summary Create a message
   * @request POST:/template/{key}/message
   * @secure
   * @response `200` `MrfiktivAdminMessageViewModelGen` The created message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreateFromTemplate = (
    { key, ...query }: MrfiktivAdminMessageControllerCreateFromTemplateParamsGen,
    data: MrfiktivCreateTemplateMessageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAdminMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/template/${key}/message`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
