/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAdminMessageControllerCreateBatchPayloadGen,
  ThgAdminMessageControllerCreateFromTemplateParamsGen,
  ThgAdminMessageControllerFindAllParamsGen,
  ThgAdminMessageInboundControllerFindAllInboundParamsGen,
  ThgAdminMessageInboundViewModelGen,
  ThgAdminMessageViewModelGen,
  ThgCreateMessageDtoGen,
  ThgCreateTemplateMessageDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgUpdateMessageDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Message<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags message
   * @name AdminMessageInboundControllerFindAllInbound
   * @summary Get all messages
   * @request GET:/message-inbound
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgAdminMessageInboundViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageInboundControllerFindAllInbound = (
    query: ThgAdminMessageInboundControllerFindAllInboundParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgAdminMessageInboundViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/message-inbound`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageInboundControllerFind
   * @summary Get a message with download link to content
   * @request GET:/message-inbound/{id}
   * @secure
   * @response `200` `ThgAdminMessageInboundViewModelGen` The found message
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageInboundControllerFind = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgAdminMessageInboundViewModelGen, ThgExceptionViewmodelGen>({
      path: `/message-inbound/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreate
   * @summary Create a message
   * @request POST:/message
   * @secure
   * @response `200` `ThgAdminMessageViewModelGen` The created message
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreate = (data: ThgCreateMessageDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgAdminMessageViewModelGen, ThgExceptionViewmodelGen>({
      path: `/message`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerFindAll
   * @summary Get all system messages
   * @request GET:/message
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgAdminMessageViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerFindAll = (query: ThgAdminMessageControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgAdminMessageViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/message`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreateBatch
   * @summary Create a batch of messages
   * @request POST:/message/batch
   * @secure
   * @response `200` `(ThgAdminMessageViewModelGen)[]` The created messages
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreateBatch = (
    data: ThgAdminMessageControllerCreateBatchPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAdminMessageViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/message/batch`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerFindOneById
   * @summary Get a message
   * @request GET:/message/{id}
   * @secure
   * @response `200` `ThgAdminMessageViewModelGen` The found message
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerFindOneById = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgAdminMessageViewModelGen, ThgExceptionViewmodelGen>({
      path: `/message/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerUpdateOneById
   * @summary Update a message
   * @request PUT:/message/{id}
   * @secure
   * @response `200` `ThgAdminMessageViewModelGen` The updated message
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerUpdateOneById = (id: string, data: ThgUpdateMessageDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgAdminMessageViewModelGen, ThgExceptionViewmodelGen>({
      path: `/message/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags message
   * @name AdminMessageControllerCreateFromTemplate
   * @summary Create a message
   * @request POST:/template/{key}/message
   * @secure
   * @response `200` `ThgAdminMessageViewModelGen` The created message
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminMessageControllerCreateFromTemplate = (
    { key, ...query }: ThgAdminMessageControllerCreateFromTemplateParamsGen,
    data: ThgCreateTemplateMessageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAdminMessageViewModelGen, ThgExceptionViewmodelGen>({
      path: `/template/${key}/message`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
