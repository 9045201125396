

































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";

@Component({
  components: { ActivityTimeLineItemDelete, TimeLineItemText, ContextMenu }
})
export default class ActivityTimeLineItemIsFixedUpdate extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  statusConfigurationMap = new Map<"true" | "false", { icon: string; color: string; text: string }>([
    [
      "true",
      {
        icon: "mdi-bookmark-check-outline",
        color: "success",
        text: this.$t("timeLine.ActivityTimeLineItemIsFixedUpdate.status.true").toString()
      }
    ],
    [
      "false",
      {
        icon: "mdi-bookmark-outline",
        color: "info",
        text: this.$t("timeLine.ActivityTimeLineItemIsFixedUpdate.status.false").toString()
      }
    ]
  ]);

  get statusConfiguration() {
    const statusConfiguration = this.statusConfigurationMap.get(this.activityLogEntry.comment as "true" | "false");

    if (!statusConfiguration) {
      return {
        icon: "mdi-eye-outline",
        color: "info",
        text: this.$t("timeLine.ActivityTimeLineItemIsFixedUpdate.status.default", {
          status: status
        })
      };
    }

    return statusConfiguration;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
