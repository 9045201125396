var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-timeline-item', {
    staticClass: "mt-n4",
    attrs: {
      "small": "",
      "hide-dot": ""
    }
  }, [_vm.canUpdateReport ? _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "align": "baseline"
    }
  }, [_c('span', {
    staticClass: "ml-3 text-caption"
  }, [_vm._v(_vm._s(_vm.$t("timeLine.CustomerContactTimeLineElement.title")))]), _c('v-spacer'), _c('partner-report-update-report-dialog', {
    staticClass: "mr-7 d-print-none",
    attrs: {
      "config": [_vm.ReportUpdateTabEnum.contact],
      "activityTarget": "accident"
    }
  })], 1) : _vm._e(), _vm.companyName || _vm.isCompany ? _c('div', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-domain")]), _vm.isCompany ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("timeLine.CustomerContactTimeLineElement.isCompanyUser")) + " ")]) : _vm._e(), _vm.companyName && _vm.isCompany ? _c('span', {
    staticClass: "ml-n1"
  }, [_vm._v(": ")]) : _vm._e(), _vm.companyName ? _c('span', [_vm._v(" " + _vm._s(_vm.companyName) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.taxnumber || _vm.isTaxDeductible ? _c('div', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-card")]), _vm.isTaxDeductible ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("timeLine.CustomerContactTimeLineElement.isTaxDeductible")) + " ")]) : _vm._e(), _vm.taxnumber && _vm.isTaxDeductible ? _c('span', {
    staticClass: "ml-n1"
  }, [_vm._v(": ")]) : _vm._e(), _vm.taxnumber ? _c('span', [_vm._v(" " + _vm._s(_vm.taxnumber) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.customerName ? _c('div', [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-account-outline")]), _vm._v(" " + _vm._s(_vm.customerName) + " ")], 1) : _vm._e(), _vm.formatedAdress ? _c('div', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-map-marker-outline",
      "text": _vm.formatedAdress
    }
  })], 1) : _vm._e(), _vm.email ? _c('div', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email-outline")]), _c('a', {
    attrs: {
      "href": "mailto:".concat(_vm.email)
    }
  }, [_vm._v(_vm._s(_vm.email))]), _vm._v(" "), _c('br')], 1) : _vm._e(), _vm.phone ? _c('div', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone-outline")]), _c('a', {
    attrs: {
      "href": "tel:".concat(_vm.phone)
    }
  }, [_vm._v(_vm._s(_vm.phone))])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }