import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { Company, ICompany } from "@/models/company.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import handoverService from "@/services/mrfiktiv/services/handoverService";
import {
  MrfiktivCreateHandoverDtoGen,
  MrfiktivHandoverCompanyDtoGen,
  MrfiktivHandoverViewModelGen,
  MrfiktivUpdateHandoverDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverDataAccessLayer } from "@/store/modules/access-layers/handover.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { IAttendee } from "./attendee";
import { IShortUser, ShortUser } from "./short-user.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { Agreement, IAgreement } from "./agreement.entity";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { DocumentModule } from "@/store/modules/document.store";

@IsFilterable
export class HandoverBase implements MrfiktivHandoverViewModelGen, ICreateDto<IHandover> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.id",
    config: {
      itemCallback: () => HandoverDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-handover"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.handover.number"
  })
  number: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.cost.date"
  })
  date?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.vehicleId",
    config: {
      itemCallback: () => VehicleModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-vehicle"
    }
  })
  vehicleIds: string[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.attachmentIds",
    config: {
      itemCallback: () => DocumentModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-document"
    }
  })
  attachmentIds: string[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.handover.title"
  })
  title: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.handover.description"
  })
  description: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.handover.state",
    config: {
      items: Object.values(HandoverStateEnum).map(v => {
        return {
          text: `enums.HandoverStateEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  state: HandoverStateEnum;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Company
  })
  parties: ICompany[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Inspection
  })
  inspections: IInspection[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    displayName: "objects.ticket.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[];

  assigneesDetails?: IShortUser[];

  @FilterConfig({
    type: Agreement
  })
  agreements: IAgreement[];

  /**
   * Construct handover
   */
  constructor(handover?: Partial<HandoverBase | MrfiktivHandoverViewModelGen>) {
    this.id = handover?.id ?? "";
    this.number = handover?.number ?? -1;

    this.partnerId = handover?.partnerId ?? "";
    this.vehicleIds = handover?.vehicleIds ?? [];
    this.attachmentIds = handover?.attachmentIds || [];

    this.title = handover?.title ?? "";
    this.description = handover?.description ?? "";
    this.date = handover?.date;

    this.state = (handover?.state ?? HandoverStateEnum.CLOSED) as HandoverStateEnum;

    this.parties = (handover?.parties ?? []).map(p => new Company(p));

    this.inspections = (handover?.inspections ?? []).map(
      i => new Inspection({ ...i, handoverId: this.id, partnerId: this.partnerId })
    );

    this.assignees = handover?.assignees || [];
    this.assigneesDetails = (handover?.assigneesDetails || []).map(u => new ShortUser(u));

    this.timestamp = new Timestamp(handover?.timestamp);

    this.agreements = (handover?.agreements ?? []).map(a => new Agreement(a));
  }

  get attendees(): IAttendee[] {
    return this.inspections.map(i => i.attendees).flat();
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    const res = await handoverService.findOne(this.partnerId, this.id);

    this.map(res);
    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  protected map(handover?: MrfiktivHandoverViewModelGen) {
    if (!handover) return;
    this.id = handover.id;
    this.number = handover.number;

    this.partnerId = handover.partnerId;
    this.vehicleIds = handover.vehicleIds;
    this.attachmentIds = handover?.attachmentIds || [];

    this.title = handover.title;
    this.description = handover.description;
    this.date = handover.date;

    this.state = handover.state as HandoverStateEnum;

    this.parties = (handover?.parties ?? []).map(p => new Company(p));

    this.inspections = (handover?.inspections ?? []).map(
      i => new Inspection({ ...i, handoverId: this.id, partnerId: this.partnerId })
    );

    this.assignees = handover?.assignees || [];
    this.assigneesDetails = (handover?.assigneesDetails || []).map(u => new ShortUser(u));

    this.timestamp = new Timestamp(handover.timestamp);

    this.agreements = (handover?.agreements ?? []).map(a => new Agreement(a));
  }

  /**
   * create fetch handover
   */
  async create() {
    const data: MrfiktivCreateHandoverDtoGen = {
      title: this.title,
      description: this.description,
      date: this.date,
      parties: this.parties as MrfiktivHandoverCompanyDtoGen[],
      attendees: this.attendees,
      vehicleIds: this.vehicleIds,
      assignees: this.assignees
    };
    const res = await handoverService.create(this.partnerId, data);

    this.map(res);

    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete handover
   */
  async delete() {
    const res = await handoverService.remove(this.partnerId, this.id);

    this.map(res);
    HandoverDataAccessLayer.delete(this);
  }

  /**
   * update handover
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateHandoverDtoGen = {
      title: this.title,
      description: this.description,
      date: this.date,
      parties: this.parties as MrfiktivHandoverCompanyDtoGen[],
      state: this.state,
      attendees: this.attendees,
      assignees: this.assignees
    };
    const res = await handoverService.update(this.partnerId, this.id, data);
    this.map(res);
    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * update handover via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateHandoverDtoGen) {
    const res = await handoverService.update(this.partnerId, this.id, dto);

    this.map(res);

    HandoverDataAccessLayer.set(this);

    return this;
  }
}

type IHandover = HandoverBase;
const Handover = Filter.createForClass(HandoverBase);

export { Handover, IHandover };
