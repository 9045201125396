













































import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { MrfiktivUpdateReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import VehicleApiSelection from "../utility/VehicleApiSelection.vue";
import { Registration } from "@/models/registration.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ReportType } from "@/store/enum/reportType";

@Component({
  components: {
    Card,
    VehicleApiSelection,
    NumberplateForm
  }
})
export default class PartnerReportCardGeneral extends Vue {
  @Ref("vehicle-api-selection")
  selection!: VehicleApiSelection;

  @Prop()
  private dto!: MrfiktivUpdateReportDtoGen | CreateReportAsPartnerDto;

  @Prop()
  value!: boolean;

  @Prop()
  vehicle!: IVehicle | null;

  @Prop({ default: false })
  loadingVehicle!: boolean;

  ActionEnum = ActionEnum;

  ResourceEnum = ResourceEnum;

  loadingVehicleOnChange = false;

  key = 0;

  get showNumberPlate() {
    return this.reportDto.reportType !== ReportType.TRAIN;
  }

  get countryCode() {
    return PartnerModule?.partner?.countryCode ?? CountryCodeEnum.germany;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get showVehicleSelection() {
    return (
      PartnerModule.partner.partnerType === PartnerTypeEnum.FLEET ||
      PartnerModule.partner.partnerType === PartnerTypeEnum.TRAIN
    );
  }

  get isValid() {
    return this.value;
  }

  set isValid(v: boolean) {
    this.$emit("input", v);
  }

  get reportDto() {
    return this.dto;
  }

  set reportDto(value) {
    this.$emit("update:dto", value);
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isNumberRule() {
    return [isNumberRule()];
  }

  async onVehicleSelect(vehicle: IVehicle) {
    this.loadingVehicleOnChange = true;
    // fetch in order to get access to blueprint images etc

    vehicle = await new Vehicle(vehicle).fetch();
    const registrationDto = new Registration({
      ...vehicle?.registration,
      numberplate: vehicle?.numberplate ?? vehicle?.registration?.numberplate,
      identificationnumber: vehicle?.identificationnumber ?? vehicle?.registration?.identificationnumber
    });

    this.reportDto.vehicleId = vehicle?.id;
    this.reportDto.numberplate = vehicle.numberplate ?? this.reportDto.numberplate;
    this.reportDto.registrationResults = registrationDto;
    this.$emit("update:vehicle", vehicle);
    this.loadingVehicleOnChange = false;
  }

  mounted() {
    this.prefillDropdropDown();
  }

  @Watch("vehicle", { deep: true })
  prefillDropdropDown() {
    // preset vehicle in selection dropdown of partnerReportCardGeneral if found
    this.$nextTick(() => {
      if (this.vehicle && this.selection) {
        this.selection.selected = this.vehicle?.id;
        this.selection.items?.push(this.vehicle);
        this.selection.selected = this.vehicle.id;
      }
    });
  }
}
