






















































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { Handover, IHandover } from "@/models/handover.entity";
import RefsInspection from "../utility/RefsInspection.vue";
import RefsHandover from "../utility/RefsHandover.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IInspection } from "@/models/inspection.entity";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IReport } from "@/models/report.entity";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { RefsHandover, RefsInspection, ConfirmActionDialog }
})
export default class HandoverTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  report!: IReport;

  loadingHandover = false;

  loadingInspection = false;

  handover: IHandover | null = null;

  isInspectionUpdateLoading = false;

  isUpdateInspectionDialogActive = false;

  selectedInspection: IInspection | null = null;

  get inspection(): IInspection | undefined {
    return this.handover?.inspections.find(i => i.id === this.report.handover?.foundInInspection);
  }

  async mounted() {
    await Promise.all([this.getHandover()]);
  }

  async getHandover() {
    try {
      this.loadingHandover = true;
      this.handover = await new Handover({
        partnerId: this.report.partnerId,
        id: this.report.handover?.handoverId
      }).fetch();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingHandover = false;
    }
  }

  goToHandoverDetail() {
    if (!this.report.handover?.handoverId) {
      return;
    }
    new GoToHelper(this.$router).goToHandoverDetail(this.report.handover.handoverId, this.report.partnerId);
  }

  goToInspectionDetail() {
    if (!this.report.handover?.handoverId || !this.report.handover?.foundInInspection) {
      return;
    }
    new GoToHelper(this.$router).goToInspectionDetail(
      this.report.handover.foundInInspection,
      this.report.handover.handoverId,
      this.report.partnerId
    );
  }

  openUpdateInspectionDialog() {
    const foundInInspectionId = this.report.handover?.foundInInspection;
    this.selectedInspection = this.handover?.inspections.find(i => i.id === foundInInspectionId) ?? null;
    this.isUpdateInspectionDialogActive = true;
  }

  async updateInspection() {
    this.isInspectionUpdateLoading = true;
    await this.report
      .updatePartial({
        handover: {
          foundInInspection: this.selectedInspection?.id
        }
      })
      .catch(handleError);
    this.isInspectionUpdateLoading = false;
    this.isUpdateInspectionDialogActive = false;
  }
}
