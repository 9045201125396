


























import { handleError } from "@/lib/utility/handleError";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import { PartnerModule } from "@/store/modules/partner";
import { debounce } from "debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import RefsVehicle from "./RefsVehicle.vue";
import { MrfiktivVehicleViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * This component searches the API directly without modifying the store.
 * items are stored in the `v-autocomplete` cache.
 */
@Component({
  components: { RefsVehicle }
})
export default class VehicleApiSelection extends Vue {
  items: MrfiktivVehicleViewModelGen[] | undefined = [];

  @Prop()
  rules!: Function[];

  @Prop()
  clearable!: boolean;

  @Prop()
  hint!: string;

  @Prop()
  persistentHint!: boolean;

  @Prop()
  loading?: boolean;

  loadingLocal = false;

  search = null;

  selected: string | null = null;

  @Watch("search")
  async onSearchChange(search: string) {
    if (search && !this.itemsExist(search)) {
      this.debounceQuerySelections(search);
    }
  }

  private itemsExist(displayName: string) {
    return this.items && this.items.some(i => i.displayName === displayName);
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  async mounted() {
    this.loadingLocal = true;
    try {
      this.items = (await vehicleService.getAll({ partnerId: this.partnerId, itemsPerPage: 25 })).data;
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingLocal = false;
    }
  }

  // We have to debounce the input of the user to not fire backend calls after each keystroke
  debounceQuerySelections = debounce(this.querySelections, 500);

  /**
   *  Searches the API directly not via the store
   * @param search the search parameter
   */
  async querySelections(search: string) {
    this.loadingLocal = true;

    try {
      this.items = (await vehicleService.getAll({ partnerId: this.partnerId, search, itemsPerPage: 25 })).data;
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingLocal = false;
    }
  }

  change(vehicleId: string) {
    const vehicle = this.items?.find(i => i.id === vehicleId);
    this.$emit("change", vehicle);
  }
}
