var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.measureIsMobile,
      expression: "measureIsMobile"
    }],
    ref: "mHeaderContainer",
    class: _vm.isMobile ? '' : 'mb-6'
  }, [_vm.breadCrumbs ? _c('v-breadcrumbs', {
    staticClass: "ml-n2 pt-2",
    attrs: {
      "items": _vm.breadCrumbs,
      "large": !_vm.isMobile
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function fn() {
        return [_c('v-icon', [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }], null, false, 3681283568)
  }) : _vm._e(), _vm.alerts ? _c('div', _vm._l(_vm.alerts, function (alert, index) {
    return _c('v-alert', {
      key: index,
      staticClass: "ma-4",
      attrs: {
        "value": true,
        "type": alert.type,
        "border": "left"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "grow"
    }, [_vm._v(" " + _vm._s(alert.text) + " ")]), alert.action ? _c('v-col', {
      staticClass: "shrink"
    }, [_c('v-btn', {
      attrs: {
        "small": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.alertClicked(alert.action);
        }
      }
    }, [_vm._v(" " + _vm._s(alert.action.text))])], 1) : _vm._e()], 1)], 1);
  }), 1) : _vm._e(), _c('div', {
    class: _vm.titleContainerHtmlClasses
  }, [_c('div', {
    class: _vm.titleHtmlClasses
  }, [_vm.$slots.title ? [_c('div', {
    staticClass: "mx-4"
  }, [_vm._t("title")], 2)] : _vm.title ? _c('div', {
    staticClass: "text-md-h4 title mx-4 text-wrap mb-2"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.loading ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-progress-circular', _vm._g(_vm._b({
          attrs: {
            "size": "14",
            "width": "2",
            "indeterminate": ""
          }
        }, 'v-progress-circular', attrs, false), on))];
      }
    }], null, false, 878070832)
  }, [_c('span', {
    staticClass: "text-small"
  }, [_vm._v("Loading...")])]) : _vm._e()], 1) : _vm._e(), _vm.$slots.subtitle ? [_c('div', {
    staticClass: "mx-4"
  }, [_vm._t("subtitle")], 2)] : _c('div', {
    staticClass: "text-medium-emphasis mx-4 "
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]), _vm._l(_vm.chips, function (chip, index) {
    return _c('span', {
      key: index
    }, [chip.tooltip ? _c('tooltip', {
      attrs: {
        "text": chip.tooltip
      }
    }, [!chip.disabled ? _c('v-chip', {
      staticClass: "mr-2 text-truncate",
      attrs: {
        "color": _vm.chipColor(chip, index),
        "dark": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(chip);
        }
      }
    }, [chip.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "left": ""
      }
    }, [_vm._v(_vm._s(chip.icon))]) : _vm._e(), _vm._v(" " + _vm._s(chip.text) + " ")], 1) : _c('v-chip', {
      staticClass: "mr-2 text-truncate",
      attrs: {
        "color": _vm.chipColor(chip, index),
        "dark": "",
        "small": ""
      }
    }, [chip.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "left": ""
      }
    }, [_vm._v(_vm._s(chip.icon))]) : _vm._e(), _vm._v(" " + _vm._s(chip.text) + " ")], 1)], 1) : !chip.disabled ? _c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "color": _vm.chipColor(chip, index),
        "dark": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(chip);
        }
      }
    }, [chip.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "left": ""
      }
    }, [_vm._v(_vm._s(chip.icon))]) : _vm._e(), _vm._v(" " + _vm._s(chip.text) + " ")], 1) : _c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "color": _vm.chipColor(chip, index),
        "dark": "",
        "small": ""
      }
    }, [chip.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "left": ""
      }
    }, [_vm._v(_vm._s(chip.icon))]) : _vm._e(), _vm._v(" " + _vm._s(chip.text) + " ")], 1)], 1);
  })], 2)], 2), _c('div', {
    staticClass: "d-print-none",
    class: _vm.actionsContainerHtmlClasses
  }, [_vm.$slots.actions ? [_c('div', [_vm._t("actions")], 2)] : _c('div', [!_vm.isMobile ? _c('div', [_vm.otherActions ? _c('v-menu', {
    attrs: {
      "close-on-click": true,
      "offset-y": true
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-1",
          attrs: {
            "outlined": "",
            "small": "",
            "color": "info"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("designGuide.MDetailForm.moreActions")) + " "), _c('v-icon', [_vm._v("mdi-menu-down")])], 1)];
      }
    }], null, false, 1245430049)
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.otherActions, function (action, index) {
    return _c('v-list', {
      key: index,
      staticClass: "mx-2"
    }, [_c('v-list-item', {
      attrs: {
        "dense": "",
        "disabled": action.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(action);
        }
      }
    }, [action.icon ? _c('v-list-item-icon', [_c('v-icon', {
      style: _vm.colorStyle(action.color)
    }, [_vm._v(_vm._s(action.icon))])], 1) : _vm._e(), _c('v-list-item-subtitle', {
      style: _vm.colorStyle(action.color)
    }, [_vm._v(" " + _vm._s(action.text) + " ")])], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.secondAction ? _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": "",
      "color": _vm.secondAction.color || 'info',
      "disabled": _vm.secondAction.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.actionClicked(_vm.secondAction);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.secondAction.text) + " ")]) : _vm._e(), _vm.firstAction ? _c('v-btn', {
    staticClass: "mr-4 ml-1",
    attrs: {
      "elevation": "0",
      "small": "",
      "color": "info",
      "disabled": _vm.firstAction.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.actionClicked(_vm.firstAction);
      }
    }
  }, [_vm._v(_vm._s(_vm.firstAction.text) + " ")]) : _vm._e()], 1) : _c('div', [_vm.mobileActions ? _c('v-menu', {
    attrs: {
      "close-on-click": true,
      "offset-y": true
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }], null, false, 3221905750)
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.actions, function (action, index) {
    return _c('v-list', {
      key: index,
      staticClass: "mx-2"
    }, [_c('v-list-item', {
      attrs: {
        "dense": "",
        "disabled": action.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(action);
        }
      }
    }, [action.icon ? _c('v-list-item-icon', [_c('v-icon', {
      style: _vm.colorStyle(action.color)
    }, [_vm._v(_vm._s(action.icon))])], 1) : _vm._e(), _c('v-list-item-subtitle', {
      style: _vm.colorStyle(action.color)
    }, [_vm._v(" " + _vm._s(action.text) + " ")])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1)])], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }