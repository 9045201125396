import { Filter, FilterConfig, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import {
  MrfiktivDocumentViewModelGen,
  MrfiktivHandoverSnapshotViewModelGen,
  MrfiktivReportViewModelGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverBase } from "./handover.entity";
import { IReport, Report } from "./report.entity";
import { IVehicle, Vehicle } from "./vehicle.entity";
import { ISignDocument, SignDocument } from "./sign-document.entity";

@IsFilterable
class HandoverSnapshotBase extends HandoverBase
  implements MrfiktivHandoverSnapshotViewModelGen, ICreateDto<IHandoverSnapshot> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Vehicle
  })
  vehicles?: IVehicle[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Report
  })
  reports: IReport[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: SignDocument
  })
  attachments?: ISignDocument[] | undefined;

  /**
   * Construct handover
   */
  constructor(handover?: Partial<HandoverSnapshotBase | MrfiktivHandoverSnapshotViewModelGen>) {
    super(handover);

    this.vehicles = (handover?.vehicles as (IVehicle | MrfiktivVehicleViewModelGen)[])?.map(v => new Vehicle(v)) ?? [];
    this.reports = ((handover?.reports as MrfiktivReportViewModelGen[]) ?? []).map(r => new Report(r));
    this.attachments = ((handover?.attachments as MrfiktivDocumentViewModelGen[]) ?? []).map(r => new SignDocument(r));
  }

  /**
   * map props from viewmodel to this
   */
  protected map(handover?: MrfiktivHandoverSnapshotViewModelGen) {
    if (!handover) return;

    super.map(handover);

    this.vehicles = handover?.vehicles?.map(v => new Vehicle(v)) ?? [];
    this.reports = ((handover?.reports as MrfiktivReportViewModelGen[]) ?? []).map(r => new Report(r));
    this.attachments = ((handover?.attachments as MrfiktivDocumentViewModelGen[]) ?? []).map(r => new SignDocument(r));
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * create fetch handover
   */
  async create(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * delete handover
   */
  async delete(): Promise<void> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * update handover
   * @returns
   */
  async update(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }
}

type IHandoverSnapshot = HandoverSnapshotBase;
const HandoverSnapshot = Filter.createForClass(HandoverSnapshotBase);

export { HandoverSnapshot, IHandoverSnapshot };
