












import { Component, Vue, Prop } from "vue-property-decorator";
import { ReportType } from "@/store/enum/reportType";

export enum DamageLocatorComponent {
  DamageLocator = "DamageLocator",
  DamageLocatorTruck = "DamageLocatorTruck"
}

@Component({
  components: {
    DamageLocator: () => import(/* webpackChunkName: "svg" */ "@/components/damagelocator/DamageLocator.vue"),
    DamageLocatorTruck: () => import(/* webpackChunkName: "svg" */ "@/components/damagelocator/DamageLocatorTruck.vue")
  }
})
export default class DamageLocatorCombined extends Vue {
  @Prop({ default: () => [] })
  damageLocations!: string[];
  /**
   * Controls the props `clickable` and `readOnly`.
   * If the damageLocator is used in a form, the user can click on
   * the parts of the vehicle, otherwise we just display the stored damages in the SVG.
   */
  @Prop({ default: false })
  isEdit!: boolean;

  @Prop()
  reportType!: ReportType;

  @Prop()
  clickedColor!: string;

  get clickable() {
    return this.isEdit ? 1 : 0;
  }

  get readOnly() {
    return this.isEdit ? false : true;
  }

  get damageLocatorComponentName() {
    switch (this.reportType) {
      case ReportType.TRUCK:
        return DamageLocatorComponent.DamageLocatorTruck;
      case ReportType.CAR:
        return DamageLocatorComponent.DamageLocator;
      default:
        return DamageLocatorComponent.DamageLocator;
    }
  }
}
