















































































































import SnapshotDetail from "@/components/handover/SnapshotDetail.vue";
import ActivityTimeLineItemDocumentCard from "@/components/partner/ActivityTimeLineItemDocumentCard.vue";
import ReportBaseTimeLine from "@/components/partner/ReportBaseTimeLine.vue";
import SharedContentAccessDescription from "@/components/partner/SharedContentAccessDescription.vue";
import SharedContentAccessExpired from "@/components/partner/SharedContentAccessExpired.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import reportService from "@/services/mrfiktiv/services/reportService";
import sharedContentService from "@/services/mrfiktiv/services/sharedContentService";
import snapshotService from "@/services/mrfiktiv/services/snapshotService";
import {
  MrfiktivReportViewModelGen,
  MrfiktivSharedContentViewmodelGen,
  MrfiktivSnapshotViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import documentService from "@/services/sign/services/document.service";
import { SignDocumentViewModelGen } from "@/services/sign/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportWithBanner,
    ReportBaseTimeLine,
    Tooltip,
    SharedContentAccessDescription,
    SharedContentAccessExpired,
    ActivityTimeLineItemDocumentCard,
    SnapshotDetail
  },
  filters: {
    detailedDate
  }
})
export default class SharedContentView extends Vue {
  loading = false;

  expired = false;

  isExpand = false;

  sharedContent: MrfiktivSharedContentViewmodelGen | null = null;

  sharedDocuments: {
    refDocument: MrfiktivReportViewModelGen | SignDocumentViewModelGen | MrfiktivSnapshotViewModelGen;
    refType: string;
    refId: string;
  }[] = [];

  /**
   * Dont offer print option if we share anything but a report - only show print button for reports
   */
  get hidePrint() {
    const isReportSharing = this.sharedDocuments.find(d => d.refType === BackendResourceEnum.REPORT);

    return !isReportSharing;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get accessToken() {
    return this.$route.params.token;
  }

  get partner() {
    return ReportModule.partner;
  }

  get user() {
    return this.sharedContent?.user;
  }

  get color() {
    return this.partner.settings?.color ?? "primary";
  }

  get report(): MrfiktivReportViewModelGen | undefined {
    return this.sharedDocuments?.find(sd => sd.refType === ResourceEnum.REPORT)
      ?.refDocument as MrfiktivReportViewModelGen;
  }

  async mounted() {
    this.loading = true;
    this.expired = false;

    const sharedDocumentAsync: Promise<{
      refDocument: MrfiktivReportViewModelGen | SignDocumentViewModelGen | MrfiktivSnapshotViewModelGen;
      refType: string;
      refId: string;
    }>[] = [];

    try {
      this.sharedContent = await sharedContentService.access(this.partner.id, this.accessToken);

      for (const ref of this.sharedContent.refs) {
        switch (ref.refType as ResourceEnum) {
          case ResourceEnum.REPORT:
            sharedDocumentAsync.push(
              reportService
                .getDocumentBySharedAccessToken(this.partner.id, ref.refId, this.accessToken)
                .then(refDocument => ({
                  refDocument: refDocument.report,
                  refType: ref.refType,
                  refId: ref.refId
                }))
            );
            break;
          case ResourceEnum.DOCUMENT:
            sharedDocumentAsync.push(
              documentService
                .getDocumentBySharedAccessToken(this.partner.id, ref.refId, this.accessToken)
                .then(refDocument => ({
                  refDocument: refDocument,
                  refType: ref.refType,
                  refId: ref.refId
                }))
            );
            break;
          case ResourceEnum.SNAPSHOT:
            sharedDocumentAsync.push(
              snapshotService
                .getDocumentBySharedAccessToken(this.partner.id, ref.refId, this.accessToken)
                .then(refDocument => ({
                  refDocument: refDocument,
                  refType: ref.refType,
                  refId: ref.refId
                }))
            );
            break;
        }
      }
    } catch (e) {
      if ((e as any).message === "Sharing document expired") {
        this.expired = true;
      } else {
        handleError(e);
      }
    } finally {
      this.loading = false;
    }

    const sharedDocuments = await Promise.all(sharedDocumentAsync);

    this.sharedDocuments.push(...sharedDocuments);
  }

  getService(resource: ResourceEnum) {
    switch (resource) {
      case ResourceEnum.REPORT:
        return reportService;
      case ResourceEnum.DOCUMENT:
        return documentService;
      case ResourceEnum.SNAPSHOT:
        return snapshotService;
    }
  }

  print() {
    window.print();
  }
}
