var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndUp && _vm.isMoreThanOneTabOpen
    }
  }, [_vm.active.report || _vm.active.position ? _c('v-tab', [_c('v-list-item', {
    attrs: {
      "data-test-report-damage": ""
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.validity.isCardDamageValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-heart-broken-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.damageTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.contact ? _c('v-tab', {
    attrs: {
      "data-test-report-contact": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isContactValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-phone ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.contactTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.date ? _c('v-tab', {
    attrs: {
      "data-test-report-date": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.validity.isDateValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-calendar-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.datePreference")) + " ")], 1)], 1) : _vm._e(), _vm.active.registration ? _c('v-tab', {
    attrs: {
      "data-test-report-registration": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isRegistrationValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-note-text-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registrationTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.accident ? _c('v-tab', {
    attrs: {
      "data-test-report-accident": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isAccidentDetailValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-car-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.witness ? _c('v-tab', {
    attrs: {
      "data-test-report-witness": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isWitnessDetailValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-binoculars ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witnessTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.leasing ? _c('v-tab', {
    attrs: {
      "data-test-report-leasing": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isLeasingValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-file-document-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.police ? _c('v-tab', {
    attrs: {
      "data-test-report-police": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isPoliceValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-police-badge-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.policeTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.preExisting ? _c('v-tab', {
    attrs: {
      "data-test-report-pre-existing": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-car-wrench ")])], 1), _vm._v(" " + _vm._s(_vm.$t("report.preexistingdamage.title")) + " ")], 1)], 1) : _vm._e(), _vm.active.insurance ? _c('v-tab', {
    attrs: {
      "data-test-report-insurance": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isInsuranceValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-file-document-multiple-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.insuranceTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.images ? _c('v-tab', {
    attrs: {
      "data-test-report-images": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isPoliceValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-image-multiple-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.imageTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.body ? _c('v-tab', {
    attrs: {
      "data-test-report-body": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isPoliceValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-attachment ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.bodyTitle")) + " ")], 1)], 1) : _vm._e(), _vm.active.message ? _c('v-tab', {
    attrs: {
      "data-test-report-message": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "left": "",
      "color": !_vm.isPoliceValid ? 'error' : ''
    }
  }, [_vm._v(" mdi-message-check-outline ")])], 1), _vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.messageTitle")) + " ")], 1)], 1) : _vm._e(), _vm._t("tabSlot"), _vm.active.report || _vm.active.position ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_vm.active.report ? _c('partner-report-card-general', {
    ref: "partnerReportCardGeneral",
    staticClass: "ma-1",
    attrs: {
      "vehicle": _vm.vehicle,
      "loadingVehicle": _vm.loadingVehicle,
      "dto": _vm.reportDto
    },
    on: {
      "update:vehicle": function updateVehicle($event) {
        _vm.vehicle = $event;
      },
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isCardGeneralValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isCardGeneralValid", $$v);
      },
      expression: "validity.isCardGeneralValid"
    }
  }) : _vm._e(), _vm.active.report || _vm.active.position ? _c('partner-report-card-damage', {
    staticClass: "ma-1",
    attrs: {
      "config": {
        position: _vm.active.report || _vm.active.position,
        report: _vm.active.report
      },
      "vehicle": _vm.vehicle,
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isCardDamageValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isCardDamageValid", $$v);
      },
      expression: "validity.isCardDamageValid"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.active.contact ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-name', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardNameValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardNameValid", $$v);
      },
      expression: "validity.isReportCardNameValid"
    }
  }), _c('partner-report-card-contact', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardContactValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardContactValid", $$v);
      },
      expression: "validity.isReportCardContactValid"
    }
  }), _c('partner-report-card-address', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardAddressValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardAddressValid", $$v);
      },
      expression: "validity.isReportCardAddressValid"
    }
  })], 1) : _vm._e(), _vm.active.date ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-date-preference', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isCardDatePreferenceValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isCardDatePreferenceValid", $$v);
      },
      expression: "validity.isCardDatePreferenceValid"
    }
  })], 1) : _vm._e(), _vm.active.registration ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('card', {
    key: "preference-registration",
    attrs: {
      "margin": 1,
      "flat": "",
      "outlined": "",
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.title'),
      "hideTitle": true
    }
  }, [_c('registration-form', {
    staticClass: "pa-2",
    model: {
      value: _vm.reportDto.registrationResults,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto, "registrationResults", $$v);
      },
      expression: "reportDto.registrationResults"
    }
  })], 1)], 1) : _vm._e(), _vm.active.accident ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-accident-details', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardAccidentDetails,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardAccidentDetails", $$v);
      },
      expression: "validity.isReportCardAccidentDetails"
    }
  })], 1) : _vm._e(), _vm.active.witness ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-witnesses', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardWitnessValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardWitnessValid", $$v);
      },
      expression: "validity.isReportCardWitnessValid"
    }
  })], 1) : _vm._e(), _vm.active.leasing ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-leasing', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardLeasingValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardLeasingValid", $$v);
      },
      expression: "validity.isReportCardLeasingValid"
    }
  })], 1) : _vm._e(), _vm.active.police ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-police', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isReportCardPoliceValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isReportCardPoliceValid", $$v);
      },
      expression: "validity.isReportCardPoliceValid"
    }
  })], 1) : _vm._e(), _vm.active.preExisting ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('card', {
    key: "preference-pre-existing",
    attrs: {
      "margin": 1,
      "flat": "",
      "outlined": "",
      "subtitle": _vm.$t('report.preexistingdamage.title'),
      "hideTitle": true
    }
  }, [_vm.reportDto.preExistingDamageDetails ? _c('v-form', {
    ref: "form",
    staticClass: "pt-2 pr-2 pl-2",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.preexistingdamage.field'),
      "flat": "",
      "outlined": "",
      "clearable": ""
    },
    model: {
      value: _vm.reportDto.preExistingDamageDetails.description,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.preExistingDamageDetails, "description", $$v);
      },
      expression: "reportDto.preExistingDamageDetails.description"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.active.insurance ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-insurances', {
    staticClass: "ma-1",
    attrs: {
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.validity.isInsuranceValid,
      callback: function callback($$v) {
        _vm.$set(_vm.validity, "isInsuranceValid", $$v);
      },
      expression: "validity.isInsuranceValid"
    }
  })], 1) : _vm._e(), _vm.active.images ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-images', {
    staticClass: "ma-1",
    attrs: {
      "vehicle": _vm.vehicle,
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    }
  })], 1) : _vm._e(), _vm.active.body ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-body', {
    staticClass: "ma-1",
    attrs: {
      "vehicle": _vm.vehicle,
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    }
  })], 1) : _vm._e(), _vm.active.message ? _c('v-tab-item', {
    attrs: {
      "transition": "scroll-y-transition"
    }
  }, [_c('partner-report-card-message', {
    staticClass: "ma-1",
    attrs: {
      "vehicle": _vm.vehicle,
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    }
  })], 1) : _vm._e(), _vm._t("tabItemSlot")], 2), _c('debug', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_vm._v(" isCardGeneralValid ")]), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.isCardGeneralValid) + " ")])], 1), _vm._l(Object.entries(_vm.validity), function (v) {
    return _c('v-list-item', {
      key: v[0] + v[1]
    }, [_c('v-list-item-content', [_vm._v(" " + _vm._s(v[0]) + " ")]), _c('v-list-item-content', [_vm._v(" " + _vm._s(v[1]) + " ")])], 1);
  })], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.reportDto) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }