

























































































































































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { DamageTypeEnum, DamageTypeTruckEnum } from "@/store/enum/damageType.enum";
import { ReportType } from "@/store/enum/reportType";
import { Component, Prop, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { MrfiktivBlueprintElementViewmodelGen, MrfiktivPositionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";

@Component({
  components: {
    Card,
    MImageMarker,
    DamageLocatorCombined
  }
})
export default class PartnerReportCardDamage extends PartnerReportCardGeneral {
  @Prop({ default: { position: true, report: true } })
  config!: { position: boolean; report: boolean };

  damageKey = 0;

  renderComponent = true;

  markers: IMarker[] = [];

  isSelector = false;

  isBlueprint = false;

  selectedBlueprint: MrfiktivBlueprintElementViewmodelGen | null = null;

  get reportTypeItems() {
    if (PartnerModule.partner?.partnerType === PartnerTypeEnum.TRAIN) {
      return [ReportType.TRAIN];
    }

    return Object.values(ReportType).filter(v => v !== ReportType.TRAIN);
  }

  get damageItems() {
    if (!this.reportDto.reportType) {
      return Object.values(DamageTypeEnum);
    }

    return this.reportDto.reportType === ReportType.CAR
      ? Object.values(DamageTypeEnum)
      : Object.values(DamageTypeTruckEnum);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get damageLocation() {
    this.forceRerender();
    return this.reportDto?.damage;
  }

  get blueprints() {
    return this.vehicle?.blueprints;
  }

  get isItPossibleToUseSelector() {
    return this.reportDto?.reportType === ReportType.CAR || this.reportDto?.reportType === ReportType.TRUCK;
  }

  get isItPossibleToUseBlueprint() {
    return this.vehicle?.blueprints?.length;
  }

  get hasDamages() {
    return this.reportDto?.damage?.length;
  }

  get hasBlueprintId() {
    return this.reportDto?.position?.blueprintId;
  }

  mounted() {
    this.initializeBlueprintAndSelector();
  }

  @Watch("isItPossibleToUseBlueprint")
  @Watch("isItPossibleToUseSelector")
  initializeBlueprintAndSelector() {
    if (this.isItPossibleToUseSelector && this.hasDamages) {
      this.isSelector = true;
      this.isBlueprint = false;
      this.markers.splice(0);
      this.reportDto.position = null;
      this.selectedBlueprint = null;

      return;
    }

    if (this.isItPossibleToUseBlueprint && this.hasBlueprintId) {
      this.isSelector = false;
      this.isBlueprint = true;
      const blueprint = this.blueprints?.find(b => b.id === this.reportDto?.position?.blueprintId);
      this.selectedBlueprint = blueprint ?? null;
      this.onBlueprintSelected();
      this.markers.splice(0);
      this.markers.push({
        left: this.reportDto.position?.coordinates?.x ?? 0,
        top: this.reportDto.position?.coordinates?.y ?? 0,
        text: this.reportDto.title
      });
      this.reportDto.damage?.splice(0);

      return;
    }

    if (this.isItPossibleToUseBlueprint) {
      this.isSelector = false;
      this.isBlueprint = true;
      const blueprint = this.vehicle?.blueprints[0];
      this.selectedBlueprint = blueprint ?? null;
      this.reportDto.damage?.splice(0);
      this.onBlueprintSelected();

      return;
    }

    if (this.isItPossibleToUseSelector) {
      this.isSelector = true;
      this.isBlueprint = false;
      this.markers.splice(0);
      this.reportDto.position = null;
      this.selectedBlueprint = null;

      return;
    }

    this.isSelector = false;
    this.isBlueprint = false;
    this.markers.splice(0);
    this.reportDto.position = null;
    this.selectedBlueprint = null;
  }

  changeToBlueprint() {
    this.isSelector = false;
    this.isBlueprint = true;

    const blueprint = this.vehicle?.blueprints[0];
    this.selectedBlueprint = blueprint ?? null;
    this.onBlueprintSelected();

    this.markers.splice(0);
    this.reportDto.damage = [];
  }

  changeToDamageSelector() {
    this.isSelector = true;
    this.isBlueprint = false;

    this.reportDto.position = null;
    this.markers.splice(0);
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }

  onReportTypeSelected(reportType: ReportType) {
    if (this.reportDto) {
      this.reportDto.reportType = reportType;
    }

    // Clear damages
    this.reportDto?.damage?.splice(0, this.reportDto?.damage?.length);
  }

  svgClicked(e: any) {
    if (!e) return;
    const newDamage = e.title;

    if (!this.reportDto.damage) {
      this.reportDto.damage = [newDamage];
    }

    const index = this.reportDto.damage?.findIndex(v => v === newDamage) ?? -1;
    if (index > -1) {
      this.reportDto.damage?.splice(index, 1);
    } else {
      this.reportDto.damage?.push(newDamage);
    }

    this.key++;
  }

  onBlueprintSelected() {
    this.reportDto.position = {
      ...(this.reportDto.position ?? {}),
      blueprintId: this.selectedBlueprint?.id
    };
    this.reportDto.damage = [];
  }

  onMarkerCreated(marker: IMarker) {
    this.reportDto.position = {
      ...((this.reportDto.position ?? {}) as MrfiktivPositionDtoGen),
      coordinates: {
        x: marker.left,
        y: marker.top
      }
    };

    this.markers.splice(0);
    this.markers.push(marker);
  }
}
